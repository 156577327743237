import React from 'react';
import { withRouter } from 'react-router';
import Cleave from 'cleave.js/react';
import { connect } from 'react-redux';

import Aside from '../common/Aside';
import StepIndicator from '../common/StepIndicator';
import View, { ViewProps } from '../common/View';
import { Dropdown } from '../common/Dropdown';
import { IndustryCategories, IndustryTypes } from '../models';
import { IsOrRadioForm } from '../common/form/IsOrRadioForm';
import { store } from '../initStore';
import { ProcessingFormFields, StoreTypes } from '../../interfaces';

import validators from '../../utils/validators';

interface IErrors extends Partial<ProcessingFormFields> {
    percentageTotal: string;
}
interface IProcessingProfileFormState {
    fields: ProcessingFormFields;
    errors: IErrors;
    activeElement: string;
}

const useProcessingState = (props: ViewProps) => {
    const initialValues = { businessCategory: '', businessType: '', businessDescription: '', currentlyAcceptingCards: 'no', swipedPercentage: '0', keyedPercentage: '0', motoPercentage: '0', ecommPercentage: '0', averageTicket: '', averageMonthlyCardVolume: '', averageMonthlySalesVolume: '' };
    const initialErrors = { businessCategory: '', businessType: '', businessDescription: '', currentlyAcceptingCards: '', swipedPercentage: '', keyedPercentage: '', motoPercentage: '', percentageTotal: '', ecommPercentage: '', averageTicket: '', averageMonthlyCardVolume: '', averageMonthlySalesVolume: '' };

    const [processingState, setProcessingState] = React.useState<IProcessingProfileFormState>({
        fields: props.processing ? { ...initialValues, ...props.processing } : { ...initialValues },
        errors: initialErrors,
        activeElement: null,
    });

    function handleChange(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        if (e.currentTarget || e.target) {
            const name = e.currentTarget.name || e.target.name;
            let value = e.currentTarget.value || e.target.value;
            if(value.replace(/^0+/, '').trim()) {
                value = value.replace(/^0+/, '')
            }
            setProcessingState(prevState => ({ ...prevState, fields: { ...prevState.fields, [name]: value }, errors: { ...prevState.errors, [name]: null } }));
        }
    }

    function handleCleaveChange(e: React.ChangeEvent<any>) {
        if (e.currentTarget || e.target) {
            const name = e.currentTarget.name || e.target.name;
            const value = e.target.rawValue || e.currentTarget.value || e.target.value;
            setProcessingState(prevState => ({ ...prevState, fields: { ...prevState.fields, [name]: value }, errors: {...prevState.errors, [name]: null} }));
        }
    }

    function handleSubmit(e: React.FormEvent) {
        e.preventDefault();
        if (formIsValid()) {
            store.dispatch({ type: 'processing', payload: { ...processingState.fields } });
            props.history.push('/instant/banking')
        } else {
            console.log({ text: `User failed validation for processing step`, fields: processingState.errors });
        }
    }

    function formIsValid() {
        const { fields: { swipedPercentage, keyedPercentage, motoPercentage, ecommPercentage, currentlyAcceptingCards }} = processingState;

        let newErrors = {} as IErrors;
        Object.values(validations).forEach(validate => {
            const error = validate();
            newErrors = {...newErrors, ...error}
        });

        if (currentlyAcceptingCards === "yes") {
            const percentageTotal = +swipedPercentage + +keyedPercentage + +motoPercentage + +ecommPercentage;

            if (percentageTotal > 100 && currentlyAcceptingCards === "yes") {
                newErrors.percentageTotal =
                    "Processing Total is greater than 100%";
            } else if (
                percentageTotal < 100 &&
                currentlyAcceptingCards === "yes"
            ) {
                newErrors.percentageTotal =
                    "Processing Total is less than 100%";
            }
        }


        setProcessingState(prevState => ({ ...prevState, errors: newErrors }));
        return Object.values(newErrors).every(field => field === '' || field === null);
    }

    function elementActive(e) {
        const name = e.target ? e.target.name : '';
        setProcessingState((prevState) => ({
            ...prevState,
            activeElement: name,
            fields: {
                ...prevState.fields,
                [name]:
                    prevState.fields[name] === "0"
                        ? ""
                        : prevState.fields[name],
            },
        }));
    }

    const validations = {
        businessCategory: () => validators.required(processingState.fields.businessCategory, 'businessCategory', 'Business Category'),
        businessType: () => validators.required(processingState.fields.businessType, 'businessType', 'Business Type'),
        businessDescription: () => processingState.fields.businessType.includes('Other') ?  validators.required(processingState.fields.businessDescription, 'businessDescription', 'Business Description') : {},
        swipedPercentage: () => processingState.fields.currentlyAcceptingCards === 'yes' ? validators.wholeNumber(processingState.fields.swipedPercentage, 'swipedPercentage', 'Swiped Percentage') : {},
        keyedPercentage: () => processingState.fields.currentlyAcceptingCards === 'yes' ? validators.wholeNumber(processingState.fields.keyedPercentage, 'keyedPercentage', 'Keyed Percentage'): {},
        motoPercentage: () => processingState.fields.currentlyAcceptingCards === 'yes' ? validators.wholeNumber(processingState.fields.motoPercentage, 'motoPercentage', 'MOTO/Phone Order Percentage'): {},
        ecommPercentage: () => processingState.fields.currentlyAcceptingCards === 'yes' ? validators.wholeNumber(processingState.fields.ecommPercentage, 'ecommPercentage', 'Ecommerce Percentage'): {},
        averageTicket: () => validators.wholeNumber(processingState.fields.averageTicket, 'averageTicket'),
        averageMonthlySalesVolume: () => validators.wholeNumber(processingState.fields.averageMonthlySalesVolume, 'averageMonthlySalesVolume'),
        averageMonthlyCardVolume: () => validators.wholeNumber(processingState.fields.averageMonthlyCardVolume, 'averageMonthlyCardVolume'),
    };

    function elementBlur(e) {
        if (e.currentTarget || e.target) {
            const name = e.currentTarget.name || e.target.name;
            const validation = validations[name];
            const value =  e.target.rawValue || e.currentTarget.value || e.target.value || '';

            if (validation) {
                const newError = validation(value);
                setProcessingState(prevState => ({ ...prevState, errors: { ...prevState.errors, ...newError }, activeElement: null }));
            }
        }
    }

    const handleBusinessCategoryChange = (e) => {
        handleChange(e);
        setProcessingState(prevState => ({ ...prevState, fields: {...prevState.fields, businessType: '', businessDescription: '' }}));
    }

    const handleBack = () => {
        const { goBack } = props.history;
        store.dispatch({ type: 'processing', payload: { ...processingState.fields } });
        goBack();
    }

    return {
        processingState,
        handleChange,
        handleCleaveChange,
        handleBack,
        handleBusinessCategoryChange,
        handleSubmit,
        elementActive,
        elementBlur,
    };
}

const Processing = (props: ViewProps) => {
    const {
        processingState,
        handleChange,
        handleCleaveChange,
        handleSubmit,
        handleBack,
        handleBusinessCategoryChange,
        elementActive,
        elementBlur,
    } = useProcessingState(props);

    const { loading = false } = store.getState() as StoreTypes;
    const { fields, errors } = processingState;
    const { businessCategory, businessType, businessDescription, currentlyAcceptingCards, swipedPercentage, keyedPercentage, motoPercentage, ecommPercentage, averageMonthlyCardVolume, averageMonthlySalesVolume, averageTicket } = fields;

    return <View>
        <Aside explainer={<>
            <h1>TELL US ABOUT YOUR CARD VOLUME</h1>
            <p>In order to get you set up, we need to know what kind of business you run and what your credit card sales look like each month.</p>
        </>} field={processingState.activeElement} />
        <form className="gp-form" id="#processing" noValidate onSubmit={handleSubmit}>
            <div className="gp-form-elements">
                <StepIndicator pageStep={2} />
                <div className="form-group">
                    <div className="input-half">
                        <Dropdown
                            selected={businessCategory}
                            selectLabel="Business Category"
                            selectName="businessCategory"
                            firstOption="Select a category..."
                            options={IndustryCategories}
                            onChange={handleBusinessCategoryChange}
                            onBlur={elementBlur}
                            error={errors.businessCategory}
                            className="input-full"
                            required />
                    </div>
                    <div className="input-half">
                        <Dropdown
                            selected={businessType}
                            selectLabel="Business Type"
                            error={errors.businessType}
                            selectName="businessType"
                            firstOption="Select a type..."
                            options={IndustryTypes[businessCategory]}
                            onChange={handleChange}
                            onBlur={elementBlur}
                            className="input-full"
                            required />
                    </div>
                    {businessType.includes('Other') && <div>
                        <label>If other, please describe your business. (150 Character Limit)</label>
                        <input type="text" name="businessDescription" value={businessDescription} onBlur={elementBlur} onChange={handleChange} maxLength={150} required />
                        {errors.businessDescription && <div className="error-message">{errors.businessDescription}</div>}
                    </div>}
                </div>
                {currentlyAcceptingCards === 'yes' ? (
                <>
                    <label>Estimate Processing by Percentage (Total = 100%)</label>
                    <div className="form-group">
                        <div className="input-half">
                            <label className="db">Swiped Percentage</label>
                            <input name="swipedPercentage" onFocus={elementActive} onBlur={elementBlur} value={swipedPercentage} onChange={handleChange} type="number" min={0} max={100} required />
                            {errors.swipedPercentage && <div className="error-message">{errors.swipedPercentage}</div>}
                        </div>
                        <div className="input-half">
                            <label className="db">Keyed Percentage</label>
                            <input name="keyedPercentage" onFocus={elementActive} onBlur={elementBlur} value={keyedPercentage} onChange={handleChange} type="number" min={0} max={100} required />
                            {errors.keyedPercentage && <div className="error-message">{errors.keyedPercentage}</div>}
                        </div>
                        <div className="input-half">
                            <label className="db">MOTO/Phone Order Percentage</label>
                            <input name="motoPercentage" onFocus={elementActive} onBlur={elementBlur} value={motoPercentage} onChange={handleChange} type="number" min={0} max={100} required />
                            {errors.motoPercentage && <div className="error-message">{errors.motoPercentage}</div>}
                        </div>
                        <div className="input-half">
                            <label className="db">Ecommerce Percentage</label>
                            <input name="ecommPercentage" onFocus={elementActive} onBlur={elementBlur} value={ecommPercentage} onChange={handleChange} type="number" min={0} max={100} required />
                            {errors.ecommPercentage && <div className="error-message">{errors.ecommPercentage}</div>}
                        </div>
                        {errors.percentageTotal && <div className="error-message">{errors.percentageTotal}</div>}
                    </div>
                </>) : null}
                <div className="form-group">
                    <div className="input-full">
                        <label>Estimate your total monthly sales volume (cash, checks, credit/debit cards, etc.)</label>
                        <Cleave
                            className="input-half"
                            name="averageMonthlySalesVolume"
                            onBlur={elementBlur}
                            value={averageMonthlySalesVolume}
                            options={{
                                prefix: '$',
                                rawValueTrimPrefix: true,
                                numeral: true,
                                numeralThousandsGroupStyle: 'thousand',
                                numeralDecimalScale: 0,
                            }}
                            onChange={handleCleaveChange}
                            maxLength={10}
                            required
                            inputMode="numeric" />
                        {errors.averageMonthlySalesVolume && <div className="error-message">{errors.averageMonthlySalesVolume}</div>}
                    </div>
                    <div className="input-full">
                        <label>Estimate just your total monthly sales from credit and debit cards.</label>
                        <Cleave
                            className="input-half"
                            name="averageMonthlyCardVolume"
                            onBlur={elementBlur}
                            value={averageMonthlyCardVolume}
                            options={{
                                prefix: '$',
                                rawValueTrimPrefix: true,
                                numeral: true,
                                numeralThousandsGroupStyle: 'thousand',
                                numeralDecimalScale: 0,
                            }}
                            onChange={handleCleaveChange}
                            maxLength={10}
                            required
                            inputMode="numeric" />
                        {errors.averageMonthlyCardVolume && <div className="error-message">{errors.averageMonthlyCardVolume}</div>}
                    </div>
                    <div className="input-full">
                        <label>Estimate your average transaction size</label>
                        <Cleave
                            className="input-half"
                            name="averageTicket"
                            value={averageTicket}
                            onBlur={elementBlur}
                            options={{
                                prefix: '$',
                                rawValueTrimPrefix: true,
                                numeral: true,
                                numeralThousandsGroupStyle: 'thousand',
                                numeralDecimalScale: 0,
                            }}
                            onChange={handleCleaveChange}
                            maxLength={10}
                            required
                            inputMode="numeric" />
                        {errors.averageTicket && <div className="error-message">{errors.averageTicket}</div>}
                    </div>
                    <div className="input-full">
                        <IsOrRadioForm label="Currently accepting cards?" name="currentlyAcceptingCards" selected={currentlyAcceptingCards} handleChange={handleChange} />
                    </div>
                </div>
                <div className="flex">
                    <button disabled={loading} type="button" className="db back mt-auto" onClick={handleBack}>Back</button>
                    <button type="submit" disabled={loading} className="submit db ml-auto mt-auto self-end">Continue</button>
                </div>
            </div>
        </form>
    </View>;
}
const mapStateToProps = state => ({ processing: state.processing });
export default connect(mapStateToProps)(withRouter(Processing));
