import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { ViewProps } from './View';

interface IStepIndicatorProps extends RouteComponentProps<{}> {
    pageStep: number;
}

export const StepIndicator = ({ isCNPAgreement = false, opportunityId = null, pageStep }: ViewProps & IStepIndicatorProps) => {
    let numberOfPages = opportunityId ? 8 : 9;
    if (isCNPAgreement) {
        numberOfPages += 1;
    }
    const pages = [...Array(numberOfPages).keys()].slice(1).map((page, i) => { // Slice removes 0
        const stage = i === pageStep ? 'current' : i < pageStep ? 'complete' : '';

        return <li key={page} className={stage}>{stage === 'complete' ? <img src="/img/icon-checkmark.svg" alt="" /> : page}</li>
    });

    return <>
        <ul className="gp-form-elements__page-indicator">
            {pages}
        </ul>
    </>
}
export default connect<{ isCNPAgreement: boolean, opportunityId: string }, {}, IStepIndicatorProps>(({ isCNPAgreement, opportunityId }: any) => ({ isCNPAgreement, opportunityId }))(withRouter(StepIndicator));
