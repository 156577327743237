import { initStore } from './components/initStore'
import { StoreTypes } from './interfaces';

type TypeOptions =
    'account'
    | 'signup'
    | 'dbaInformation'
    | 'legalInformation'
    | 'owners'
    | 'processing'
    | 'SET_CNP'
    | 'cnpAgreement'
    | 'banking'
    | 'productPath'
    | 'products'
    | 'selectedProducts'
    | 'checkout'
    | 'loading'
    | 'partnerInfo'
    | 'reset'
    | 'SET_PRICING_CODE'
    | 'SET_PARTNER'
    | 'additionalDocs'
    | 'delivery'
    | 'opportunity'
    | 'error';

export const reducer = (state: StoreTypes = {} as StoreTypes, action: { type: TypeOptions; payload?: any }): StoreTypes => {
    const { type, payload } = action;

    switch (type) {
        case 'account':
            // @ts-ignore
            return { ...state, account: { ...state.createAccount, ...payload } };
        case 'signup':
            return {
                ...state,
                signup: { ...payload.signup },
                utmParams: { ...payload.utmParams },
            };
        case 'dbaInformation':
            return { ...state, dbaInformation: { ...payload } };
        case 'legalInformation':
            return { ...state, legalInformation: { ...payload } };
        case 'owners':
            return { ...state, owners: [...payload] };
        case 'processing':
            return { ...state, processing: { ...payload } };
        case 'SET_CNP':
            return { ...state, isCNPAgreement: payload.cnpAgreementRequired};
        case 'cnpAgreement':
            return { ...state, cnpAgreement: { ...payload }}
        case 'delivery':
            return { ...state, delivery: { ...payload } };
        case 'banking':
            return { ...state, banking: { ...payload.banking }, bankName: payload.bankName };
        case 'additionalDocs':
            return { ...state, additionalDocs: { ...payload } };
        case 'productPath':
            return { ...state, products: { ...payload } };
        case 'products':
            return { ...state, products: { ...payload } };
        case 'selectedProducts':
            return { ...state, selectedProducts: { ...payload } };
        case 'checkout':
            // @ts-ignore
            return { ...state, checkout: { ...payload } };
        case 'loading':
            // @ts-ignore
            return { ...state, loading: payload ? payload.loading : !state.loading };
        case 'partnerInfo':
            return {
                ...state,
                partnerInfo: payload,
                pricingCode: payload.pricingCode || 'DEFAULT',
                // @ts-ignore
                processing: (payload.industry) ? {
                    businessCategory: payload.industry.category || '',
                    businessType: payload.industry.type || '',
                } : null,
            };
        case 'opportunity':
            return { ...state, opportunityId: payload.opportunity };
        case 'SET_PRICING_CODE':
            return {
                ...state,
                pricingCode: payload.pricingCode || 'DEFAULT',
            }
        case 'reset':
            return initStore
        case 'error':
            return { ...state, error: payload.error, loading: false };
        case 'SET_PARTNER':
        default:
            return state;
    }
}
