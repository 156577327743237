import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import Cleave from 'cleave.js/react';
import 'cleave.js/dist/addons/cleave-phone.us';

import View, { ViewProps } from '../common/View';
import Aside from '../common/Aside';
import StepIndicator from '../common/StepIndicator';
import { AddressForm } from '../common/form/AddressForm';
import { store } from '../initStore';
import { DBAFields, StoreTypes } from '../../interfaces';

import validators from '../../utils/validators';
import { IsOrRadioForm } from '../common/form/IsOrRadioForm'

interface IDbaFormState {
    fields: DBAFields;
    errors: DBAFields;
    activeElement: string;
}

const useDBAInformationState = (props: ViewProps) => {
    const initialValues = { phone: '', email: '', website: '', addressLineOne: '', addressLineTwo: '', city: '', state: '', zipcode: '', isLegalAddress: 'yes' };
    const initialErrors = { phone: '', email: '', website: '', addressLineOne: '', addressLineTwo: '', city: '', state: '', zipcode: '', isLegalAddress: '' };

    const [dbaInformationState, setDBAInformationState] = React.useState<IDbaFormState>({
        fields: props.dbaInformation ? { ...props.dbaInformation } : { ...initialValues },
        errors: initialErrors,
        activeElement: null,
    });

    function handleChange(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        if (e.currentTarget || e.target) {
            const name = e.currentTarget.name || e.target.name;
            const value = e.currentTarget.value || e.target.value;
            setDBAInformationState(prevState => ({ ...prevState, fields: { ...prevState.fields, [name]: value }, errors: {...prevState.errors, [name]: null} }));
        }
    }

    const validations = {
        phone: () => validators.phone(dbaInformationState.fields.phone),
        website: () => validators.website(dbaInformationState.fields.website),
        email: () => validators.email(dbaInformationState.fields.email),
        addressLineOne: () => validators.addressLineOne(dbaInformationState.fields.addressLineOne),
        city: () => validators.city(dbaInformationState.fields.city),
        state: () => validators.required(dbaInformationState.fields.state, 'state', 'State'),
        zipcode: () => validators.zipcode(dbaInformationState.fields.zipcode),
    };

    function elementBlur(e) {
        if (e.currentTarget || e.target) {
            const name = e.currentTarget.name || e.target.name;
            const validation = validations[name];
            let value = e.currentTarget.value || e.target.value || '';

            if (validation) {
                const newError = validation(value);
                if (!newError[name] && value.trim()) {
                    //Auto save functionality will be here
                    if (name === 'website' && value.trim()) {
                        if (!value.startsWith('https://')) {
                            value = `https://${value}`;
                            setDBAInformationState(prevState => ({ ...prevState, fields: { ...prevState.fields, website: value }}));
                        }
                    }
                }
                setDBAInformationState(prevState => ({ ...prevState, errors: { ...prevState.errors, ...newError }}));
            }
        }
    }

    function handleSubmit(e: React.FormEvent) {
        e.preventDefault();

        if (formIsValid()) {
            store.dispatch({ type: 'dbaInformation', payload: { ...dbaInformationState.fields } });

            if (props.isBusiness) props.history.push('/instant/business/legal');
            if (props.isPersonal) props.history.push('/instant/personal/legal');
        } else {
            console.log({ text: `User failed validation for DBAInformation step`, fields: dbaInformationState.errors });
        }
    }

    function handleCleaveChange(e: React.ChangeEvent<any>) {
        if (e.currentTarget || e.target) {
            const name = e.currentTarget.name || e.target.name;
            const value = e.currentTarget.value || e.target.value;

            setDBAInformationState(prevState => ({ ...prevState, fields: { ...prevState.fields, [name]: value }, errors: {...prevState.errors, [name]: null} }));
        }
    }

    function formIsValid() {
        let newErrors = {} as DBAFields;
        Object.values(validations).forEach(validate => {
            const error = validate();
            newErrors = {...newErrors, ...error}
        });
        setDBAInformationState(prevState => ({ ...prevState, errors: newErrors }));
        return Object.values(newErrors).every(field => field === '' || field === null);
    }

    const handleBack = () => {
        const { goBack } = props.history;
        store.dispatch({ type: 'dbaInformation', payload: { ...dbaInformationState.fields } });
        goBack();
    }

    return {
        dbaInformationState,
        handleChange,
        handleBack,
        handleSubmit,
        handleCleaveChange,
        elementBlur,
    }
}

const DBAInformation = (props: ViewProps) => {
    const {
        dbaInformationState,
        handleChange,
        handleBack,
        handleSubmit,
        elementBlur,
        handleCleaveChange,
    } = useDBAInformationState(props);

    const { fields, errors } = dbaInformationState;
    const { phone, email, website, isLegalAddress } = dbaInformationState.fields;
    const { loading = false } = store.getState() as StoreTypes;

    return <View>
        <Aside
            explainer={<>
                <h1>Tell us where you do business!</h1>
                <p>Now that we know a bit more about you, we want to learn the details of your business. Let’s start with where your business operates, and your DBA information.</p>
            </>}
            field={dbaInformationState.activeElement}
        />
        <form className="gp-form" id="#dbaInformation" noValidate onSubmit={handleSubmit}>
            <div className="gp-form-elements">
                <StepIndicator pageStep={1} />
                <div className="form-group">
                    <AddressForm
                        title="Physical Business Address"
                        fields={fields}
                        onBlur={elementBlur}
                        errors={errors}
                        handleChange={handleChange} />
                    <div className={props.isBusiness ? 'input-third' : 'input-half'}>
                        <label>Business Email</label>
                        <input value={email} onChange={handleChange} onBlur={elementBlur} name="email" type="email" maxLength={40} required />
                        {dbaInformationState.errors.email && <div className="error-message">{dbaInformationState.errors.email}</div>}
                    </div>
                    <div className={props.isBusiness ? 'input-third' : 'input-half'}>
                        <label>Business Phone</label>
                        <Cleave
                            name="phone"
                            value={phone}
                            onBlur={elementBlur}
                            options={{  blocks: [3, 3, 4], delimiters: ['-', '-'], numericOnly: true }}
                            onChange={handleCleaveChange}
                            required
                            inputMode="numeric" />
                        {dbaInformationState.errors.phone && <div className="error-message">{dbaInformationState.errors.phone}</div>}
                    </div>
                    <div className="input-third">
                        <label>Business Website</label>
                        <input value={website} onChange={handleChange} onBlur={elementBlur} type="url" maxLength={40} name="website" />
                        {dbaInformationState.errors.website && <div className="error-message">{dbaInformationState.errors.website}</div>}
                    </div>
                </div>
                <fieldset className="form-group">
                    <IsOrRadioForm
                        label="Is this also your legal business address?"
                        name="isLegalAddress"
                        selected={isLegalAddress}
                        handleChange={handleChange} />
                </fieldset>
                <div className="flex">
                    <button type="button" disabled={loading} className="db back mt-auto" onClick={handleBack}>Back</button>
                    <button type="submit" disabled={loading} className="submit db ml-auto mt-auto self-end">Continue</button>
                </div>
            </div>
        </form>
    </View>;
}
const mapStateToProps = state => ({ dbaInformation: state.dbaInformation });
export default connect(mapStateToProps)(withRouter(DBAInformation));
