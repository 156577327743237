import React, { ReactNode } from 'react';
import { RouteComponentProps, RouteProps, withRouter } from 'react-router';

import { SignupFields, LegalInformationFields, BusinessOwnerType, ProcessingFormFields, BankingInfoFields, DBAFields, Product, AdditionalDocFields, DeliveryFields } from '../../interfaces';

interface IView extends RouteComponentProps<{}> {
    children: ReactNode;
    isBusiness?: boolean;
    isPersonal?: boolean;
    signup?: SignupFields;
    legalInformation?: LegalInformationFields;
    dbaInformation?: DBAFields;
    deliveryGS?: DeliveryFields;
    owner?: BusinessOwnerType;
    owners?: BusinessOwnerType[];
    processing?: ProcessingFormFields;
    banking?: BankingInfoFields;
    additionalDocs?: AdditionalDocFields;
    products?: { [key: string]: Product },
    loading?: boolean,
    opportunityId?: string,
}

export type ViewProps = IView & RouteProps;

export const View = ({ children }: ViewProps) =>
    <div className="gp-view">
        {children}
    </div>

export default withRouter(View)
