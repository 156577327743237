import React from "react";
import Aside from "components/common/Aside";
import View, { ViewProps } from "../common/View";
import { DeliveryFields } from "interfaces";
import { store } from "../initStore";
import validators from "utils/validators";
import StepIndicator from "components/common/StepIndicator";
import { IsOrRadioForm } from "components/common/form/IsOrRadioForm";
import Cleave from "cleave.js/react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

interface IErrors extends Partial<DeliveryFields> {
    percentageTotal: string;
}

interface IDeliveryState {
    fields: DeliveryFields;
    errors: IErrors;
    activeElement: string;
}

const useDeliveryState = (props: ViewProps) => {
    const initialValues: DeliveryFields = {
        refundPolicyInDays: "",
        isGSDeliveryAfterTxnDay: "no",
        delivery_immediate_Percentage: "0",
        delivery_1_3_DaysPercentage: "0",
        delivery_4_7_DaysPercentage: "0",
        delivery_8_14_DaysPercentage: "0",
        delivery_15_30_DaysPercentage: "0",
        delivery_Over_30_DaysPercentage: "0",
    };
    const initialErrors: IErrors = {
        refundPolicyInDays: "",
        isGSDeliveryAfterTxnDay: "",
        delivery_immediate_Percentage: "",
        delivery_1_3_DaysPercentage: "",
        delivery_4_7_DaysPercentage: "",
        delivery_8_14_DaysPercentage: "",
        delivery_15_30_DaysPercentage: "",
        delivery_Over_30_DaysPercentage: "",
        percentageTotal: "",
    };
    const [deliveryState, setDeliveryState] = React.useState<IDeliveryState>({
        fields: props.delivery ? { ...props.delivery } : { ...initialValues },
        errors: initialErrors,
        activeElement: null,
    });

    const validations = {
        delivery_immediate_Percentage: () =>
            deliveryState.fields.isGSDeliveryAfterTxnDay === "yes"
                ? validators.wholeNumber(
                      deliveryState.fields.delivery_immediate_Percentage,
                      "delivery_immediate_Percentage",
                      "Immediate Percentage"
                  )
                : {},
        delivery_1_3_DaysPercentage: () =>
            deliveryState.fields.isGSDeliveryAfterTxnDay === "yes"
                ? validators.wholeNumber(
                      deliveryState.fields.delivery_1_3_DaysPercentage,
                      "delivery_1_3_DaysPercentage",
                      ""
                  )
                : {},
        delivery_4_7_DaysPercentage: () =>
            deliveryState.fields.isGSDeliveryAfterTxnDay === "yes"
                ? validators.wholeNumber(
                      deliveryState.fields.delivery_4_7_DaysPercentage,
                      "delivery_4_7_DaysPercentage",
                      ""
                  )
                : {},
        delivery_8_14_DaysPercentage: () =>
            deliveryState.fields.isGSDeliveryAfterTxnDay === "yes"
                ? validators.wholeNumber(
                      deliveryState.fields.delivery_8_14_DaysPercentage,
                      "delivery_8_14_DaysPercentage",
                      ""
                  )
                : {},
        delivery_15_30_DaysPercentage: () =>
            deliveryState.fields.isGSDeliveryAfterTxnDay === "yes"
                ? validators.wholeNumber(
                      deliveryState.fields.delivery_15_30_DaysPercentage,
                      "delivery_15_30_DaysPercentage",
                      ""
                  )
                : {},
        refundPolicyInDays: () =>
            validators.wholeNumber(
                deliveryState.fields.refundPolicyInDays,
                "refundPolicyInDays",
                "No. of days"
            ),
    };

    function elementBlur(e) {
        if (e.currentTarget || e.target) {
            const name = e.currentTarget.name || e.target.name;
            const validation = validations[name];
            if (validation) {
                const newError = validation();
                setDeliveryState((prevState) => ({
                    ...prevState,
                    errors: { ...prevState.errors, ...newError },
                }));
            }
        }
    }

    function handleChange(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        if (e.currentTarget || e.target) {
            const name = e.currentTarget.name || e.target.name;
            let value = e.currentTarget.value || e.target.value;
            if (value.replace(/^0+/, "").trim()) {
                value = value.replace(/^0+/, "");
            }
            setDeliveryState((prevState) => ({
                ...prevState,
                fields: { ...prevState.fields, [name]: value },
                errors: { ...prevState.errors, [name]: null },
            }));
        }
    }

    function handleCleaveChange(e: React.ChangeEvent<any>) {
        if (e.currentTarget || e.target) {
            const name = e.currentTarget.name || e.target.name;
            let value = e.target.value || e.currentTarget.value;
            if (value.replace(/^0+/, "").trim()) {
                value = value.replace(/^0+/, "");
            }
            setDeliveryState((prevState) => ({
                ...prevState,
                fields: { ...prevState.fields, [name]: value },
                errors: { ...prevState.errors, [name]: null },
            }));
        }
    }

    function handleSubmit(e: React.FormEvent) {
        e.preventDefault();
        if (formIsValid()) {
            store.dispatch({
                type: "delivery",
                payload: { ...deliveryState.fields },
            });
            if (props.opportunityId) {
                if (props.isCNPAgreement) {
                    props.history.push("/instant/cnp-agreement");
                } else {
                    props.history.push("/instant/banking");
                }
            } else {
                props.history.push("/instant/processing");
            }
        } else {
            console.log({
                text: `User failed validation for delivery step`,
                fields: deliveryState.errors,
            });
        }
    }

    function formIsValid() {
        let newErrors = {} as IErrors;
        const {
            fields: {
                delivery_immediate_Percentage,
                delivery_15_30_DaysPercentage,
                delivery_1_3_DaysPercentage,
                delivery_4_7_DaysPercentage,
                delivery_8_14_DaysPercentage,
                delivery_Over_30_DaysPercentage,
                isGSDeliveryAfterTxnDay,
            },
        } = deliveryState;

        if (isGSDeliveryAfterTxnDay === "yes") {
            const percentageTotal =
                +delivery_immediate_Percentage +
                +delivery_1_3_DaysPercentage +
                +delivery_4_7_DaysPercentage +
                +delivery_8_14_DaysPercentage +
                +delivery_15_30_DaysPercentage +
                +delivery_Over_30_DaysPercentage;

            if (percentageTotal > 100 && isGSDeliveryAfterTxnDay === "yes") {
                newErrors.percentageTotal = "Total is greater than 100%";
            } else if (
                percentageTotal < 100 &&
                isGSDeliveryAfterTxnDay === "yes"
            ) {
                newErrors.percentageTotal = "Total is less than 100%";
            }
        }

        Object.values(validations).forEach((validate) => {
            const error = validate();
            newErrors = { ...newErrors, ...error };
        });
        setDeliveryState((prevState) => ({ ...prevState, errors: newErrors }));
        return Object.values(newErrors).every(
            (field) => field === "" || field === null
        );
    }

    function elementActive(e) {
        const name: string = e.target ? e.target.name : "";
        setDeliveryState((prevState) => ({
            ...prevState,
            activeElement: name,
        }));
    }

    const handleBack = () => {
        const { goBack } = props.history;
        store.dispatch({
            type: "delivery",
            payload: { ...deliveryState.fields },
        });
        goBack();
    };

    return {
        deliveryState,
        handleChange,
        elementBlur,
        handleCleaveChange,
        handleSubmit,
        handleBack,
        elementActive,
    };
};

const DeliveryGS = (props: ViewProps) => {
    const {
        deliveryState,
        handleChange,
        elementBlur,
        handleCleaveChange,
        handleSubmit,
        handleBack,
    } = useDeliveryState(props);
    const { loading = false } = props;
    const {
        refundPolicyInDays,
        isGSDeliveryAfterTxnDay,
        delivery_immediate_Percentage,
        delivery_1_3_DaysPercentage,
        delivery_4_7_DaysPercentage,
        delivery_8_14_DaysPercentage,
        delivery_15_30_DaysPercentage,
        delivery_Over_30_DaysPercentage,
    } = deliveryState.fields;
    return (
        <View>
            <Aside
                explainer={
                    <>
                        <h1>Delivery of Goods/Service</h1>
                    </>
                }
                field={deliveryState.activeElement}
            />
            <form
                className="gp-form"
                id="#deliveryGS"
                noValidate
                onSubmit={handleSubmit}
            >
                <div className="gp-form-elements">
                    <StepIndicator pageStep={4} />
                    <div className="form-group">
                        <div className="input-half">
                            <label>
                                How many days from purchase does a customer have
                                to return and/or cancel their order?
                            </label>
                            <input
                                className="input-full"
                                value={refundPolicyInDays}
                                onChange={handleChange}
                                onBlur={elementBlur}
                                placeholder="No. of days"
                                type="number"
                                name="refundPolicyInDays"
                                min={0}
                                required
                            />
                            {deliveryState.errors.refundPolicyInDays && (
                                <div className="error-message">
                                    {deliveryState.errors.refundPolicyInDays}
                                </div>
                            )}
                        </div>
                        <div className="input-full">
                            <div className="input-full mt3">
                                <IsOrRadioForm
                                    label="Are any of your products/service provided to customers one or more days after the credit card transaction is completed?"
                                    name="isGSDeliveryAfterTxnDay"
                                    selected={isGSDeliveryAfterTxnDay}
                                    handleChange={handleChange}
                                />
                            </div>
                        </div>
                        {isGSDeliveryAfterTxnDay === "yes" ? (
                            <>
                                <label className="mt3 mb3">
                                    What is the time frame from when payment is
                                    collected to when the product/service is
                                    provided?
                                </label>
                                <div className="input-third">
                                    <label>Immediately (Percentage %)</label>
                                    <Cleave
                                        name="delivery_immediate_Percentage"
                                        onBlur={elementBlur}
                                        value={delivery_immediate_Percentage}
                                        options={{
                                            numeralPositiveOnly: true,
                                            numericOnly: true,
                                        }}
                                        onChange={handleCleaveChange}
                                        max={100}
                                        maxLength={3}
                                        min={0}
                                        required
                                        inputMode="numeric"
                                    />
                                    {deliveryState.errors
                                        .delivery_immediate_Percentage && (
                                        <div className="error-message">
                                            {
                                                deliveryState.errors
                                                    .delivery_immediate_Percentage
                                            }
                                        </div>
                                    )}
                                </div>
                                <div className="input-third">
                                    <label>1 - 3 days (Percentage %)</label>
                                    <Cleave
                                        name="delivery_1_3_DaysPercentage"
                                        onBlur={elementBlur}
                                        value={delivery_1_3_DaysPercentage}
                                        options={{
                                            numeralPositiveOnly: true,
                                            numericOnly: true,
                                        }}
                                        onChange={handleCleaveChange}
                                        max={100}
                                        min={0}
                                        maxLength={3}
                                        required
                                        inputMode="numeric"
                                    />
                                    {deliveryState.errors
                                        .delivery_1_3_DaysPercentage && (
                                        <div className="error-message">
                                            {
                                                deliveryState.errors
                                                    .delivery_1_3_DaysPercentage
                                            }
                                        </div>
                                    )}
                                </div>
                                <div className="input-third">
                                    <label>4 - 7 days (Percentage %)</label>
                                    <Cleave
                                        name="delivery_4_7_DaysPercentage"
                                        onBlur={elementBlur}
                                        value={delivery_4_7_DaysPercentage}
                                        options={{
                                            numeralPositiveOnly: true,
                                            numericOnly: true,
                                        }}
                                        onChange={handleCleaveChange}
                                        max={100}
                                        maxLength={3}
                                        required
                                        inputMode="numeric"
                                    />
                                    {deliveryState.errors
                                        .delivery_4_7_DaysPercentage && (
                                        <div className="error-message">
                                            {
                                                deliveryState.errors
                                                    .delivery_4_7_DaysPercentage
                                            }
                                        </div>
                                    )}
                                </div>
                                <div className="input-third">
                                    <label>8 - 14 days (Percentage %)</label>
                                    <Cleave
                                        name="delivery_8_14_DaysPercentage"
                                        onBlur={elementBlur}
                                        value={delivery_8_14_DaysPercentage}
                                        options={{
                                            numeralPositiveOnly: true,
                                            numericOnly: true,
                                        }}
                                        onChange={handleCleaveChange}
                                        max={100}
                                        maxLength={3}
                                        required
                                        inputMode="numeric"
                                    />
                                    {deliveryState.errors
                                        .delivery_8_14_DaysPercentage && (
                                        <div className="error-message">
                                            {
                                                deliveryState.errors
                                                    .delivery_8_14_DaysPercentage
                                            }
                                        </div>
                                    )}
                                </div>
                                <div className="input-third">
                                    <label>15 - 30 days (Percentage %)</label>
                                    <Cleave
                                        name="delivery_15_30_DaysPercentage"
                                        onBlur={elementBlur}
                                        value={delivery_15_30_DaysPercentage}
                                        options={{
                                            numeralPositiveOnly: true,
                                            numericOnly: true,
                                        }}
                                        onChange={handleCleaveChange}
                                        max={100}
                                        maxLength={3}
                                        required
                                        inputMode="numeric"
                                    />
                                    {deliveryState.errors
                                        .delivery_15_30_DaysPercentage && (
                                        <div className="error-message">
                                            {
                                                deliveryState.errors
                                                    .delivery_15_30_DaysPercentage
                                            }
                                        </div>
                                    )}
                                </div>
                                <div className="input-third">
                                    <label>Over 30 days (Percentage %)</label>
                                    <Cleave
                                        name="delivery_Over_30_DaysPercentage"
                                        onBlur={elementBlur}
                                        value={delivery_Over_30_DaysPercentage}
                                        options={{
                                            numeralPositiveOnly: true,
                                            numericOnly: true,
                                        }}
                                        onChange={handleCleaveChange}
                                        max={100}
                                        maxLength={3}
                                        required
                                        inputMode="numeric"
                                    />
                                    {deliveryState.errors
                                        .delivery_Over_30_DaysPercentage && (
                                        <div className="error-message">
                                            {
                                                deliveryState.errors
                                                    .delivery_Over_30_DaysPercentage
                                            }
                                        </div>
                                    )}
                                </div>
                                {deliveryState.errors.percentageTotal && (
                                    <div className="error-message">
                                        {deliveryState.errors.percentageTotal}
                                    </div>
                                )}
                            </>
                        ) : null}
                    </div>
                    <div className="flex">
                        <button
                            type="button"
                            disabled={loading}
                            className="db back mt-auto"
                            onClick={handleBack}
                        >
                            Back
                        </button>
                        <button
                            type="submit"
                            disabled={loading}
                            className="submit db ml-auto mt-auto self-end"
                        >
                            Continue
                        </button>
                    </div>
                </div>
            </form>
        </View>
    );
};

const mapStateToProps = (state) => ({ delivery: state.delivery, isCNPAgreement: state.isCNPAgreement, opportunityId: state.opportunityId });
export default connect(mapStateToProps)(withRouter(DeliveryGS));
