import { createStore } from 'redux';

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { reducer } from '../reducers';

import { StoreTypes, Product } from '../interfaces'

const persistConfig = {
    key: 'root',
    storage,
}

const persistedReducer = persistReducer(persistConfig, reducer)

export const products: { [key: string]: Product } = {
    cloverMini: {
        name: 'Clover Mini',
        description: 'Perfect Countertop Solution',
        features: [
            'Connects via WiFi/Ethernet',
            'Barcode Scanner',
            '7" Glass Touch Display',
            '6.47" H x 8" W x 3.73" D, 2.3 lbs.',
        ],
        price: 439,
        minQty: 0,
        maxQty: 3,
        code: 'cloverMini',
        imageSrc: '/img/clover_mini.png',
        identifier: 'CLOVERMINI_GI',
    },
    cloverGo: {
        name: 'Clover Go',
        description: 'Payments On The Go',
        features: [
            'Connects via Bluetooth',
            'Email/Text Receipts',
            'Chargeable',
            'Compact',
            '2.5" H x 2.6" W x 0.88" D, 2.3 oz.',
        ],
        price: 49,
        minQty: 0,
        maxQty: 3,
        code: 'cloverGo',
        imageSrc: '/img/clover_go.png',
        identifier: 'CLOVERGO_GI',
    },
    fd130: {
        name: 'FD130',
        description: 'Terminal On The Go',
        features: [
            'EMV-ready',
            'Embedded NFC reader',
            'Fully Wi-Fi capable',
            'Dial-up connectivity available',
            '4.5" H x 6.1" W x 11.1" D, 3 lbs. ',
        ],
        price: 325,
        minQty: 0,
        maxQty: 3,
        code: 'fd130',
        imageSrc: '/img/fd130.jpg',
        identifier: 'FD_130',
    },
    cloverFlex: {
        name: 'Clover Flex',
        description: 'All-In-One Anywhere Device',
        features: [
            'EMV-ready',
            'Embedded NFC reader',
            'Fully Wi-Fi capable',
            'Built-in Battery',
            '7.7" H x 3.2" W x 2" D, 1.5 lbs. ',
        ],
        price: 435,
        minQty: 0,
        maxQty: 3,
        code: 'cloverFlex',
        imageSrc: '/img/clover_flex.jpg',
        identifier: 'CLOVER_FLEX',
    },
    swipeSimpleBasic: {
        default: true,
        name: 'Swipe Simple (Online Only)',
        description: 'Accept Payments Online',
        features: [
            'Easy to learn & Use ',
            'Virtual Terminal available',
            'No reader needed',
        ],
        price: 0,
        minQty: 0,
        maxQty: 3,
        code: 'swipeSimpleBasic',
        imageSrc: '/img/Swipe-Simple-B250.png',
        identifier: 'SWIPE_SIMPLE_BASIC',
    },
    swipeSimple: {
        name: 'Swipe Simple B250',
        description: 'Add a Physical Reader',
        features: [
            'EMV-ready',
            'Embedded NFC reader',
            'Fully Wi-Fi capable',
            'Built-in Battery',
            '2.4" H x 2.6" W x 0.7" D, 0.15 lbs. ',
        ],
        price: 100,
        minQty: 0,
        maxQty: 3,
        code: 'swipeSimple',
        imageSrc: '/img/Swipe-Simple-B250.png',
        identifier: 'SWIPE_SIMPLE_EMV_CONTACTLESS',
    },
}


export const initStore: StoreTypes = {
    account: null,
    additionalDocs: null,
    signup: null,
    isCNPAgreement: false,
    cnpAgreement: null,
    dbaInformation: null,
    legalInformation: null,
    owners: null,
    processing: null,
    banking: null,
    bankName: null,
    products,
    utmParams: null,
    loading: false,
    partnerInfo: null,
    pricingCode: null,
    opportunityId: null,
    delivery: null,
    error: null,
    selectedProducts: null,
}

export const store = createStore(persistedReducer, initStore as any);
export const persistor = persistStore(store)

export const purgeStore = async () => {
    await persistor.purge()

    store.dispatch({ type: 'reset' });
}
