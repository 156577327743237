import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux';
import { datadogRum } from '@datadog/browser-rum';
import { createTheme, ThemeProvider } from '@mui/material';

import Signup from './components/views/Signup';
import Personal from './components/views/Personal';
import Business from './components/views/Business';
import Processing from './components/views/Processing';
import Banking from './components/views/Banking';
import Checkout from './components/views/Checkout';

import { store, persistor } from './components/initStore';
import LeadCapture from './components/common/LeadCapture';
import Loading from './components/common/Loading'
import CreateApp from 'components/views/CreateApp';
import AdditionalDocuments from 'components/views/AdditionalDocuments';
import CreateOpportunity from 'components/views/CreateOpportunity';
import ErrorPage from 'components/views/ErrorPage';
import DeliveryGS from 'components/views/DeliveryGS';
import CNPAgreement from 'components/views/CNPAgreement';

const PageNotFound = () => <h1>Page Not Found</h1>

if (process.env.REACT_APP_DD_ENABLED) {
    datadogRum.init({
        applicationId: '5fcd5232-9332-4c77-b967-d15ca58e9391',
        clientToken: 'pubb4c46f281733fc15d4cfe281a1be2af0',
        site: 'datadoghq.com',
        env: process.env.REACT_APP_INSTANT_ENV,
        service: 'gravity-instant',
        sampleRate: 100,
        sessionReplaySampleRate: 100,
        trackInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'allow',
    });
    datadogRum.startSessionReplayRecording();
}

const theme = createTheme({
   palette: {
       primary: {
           main: '#91c73d',
       },
   }
});

export default () => {
    return <div className="App">
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <ThemeProvider theme={theme}>
                    <Router>
                        <Switch>
                            <Route exact path="/:partnerName?" component={props => <Signup {...props} />} />
                            <Route path="/instant/signup" component={props => <Signup {...props} />} />
                            <Route path="/instant/error" component={ErrorPage} />
                            <LeadCapture>
                                <Route path="/instant/business" component={props => <Business isBusiness {...props} />} />
                                <Route path="/instant/personal" component={props => <Personal isPersonal {...props} />} />
                                <Route path="/instant/processing" component={props => <Processing {...props} />} />
                                <Route path="/instant/banking" component={props => <Banking {...props} />} />
                                <Route path="/instant/additional-docs" component={props => <AdditionalDocuments {...props} />} />
                                <Route path="/instant/create-app" component={props => <CreateApp {...props} />} />
                                <Route path="/instant/delivery-goods-service" component={props => <DeliveryGS {...props} />} />
                                <Route path="/instant/cnp-agreement" component={props => <CNPAgreement {...props} />} />
                                <Route path="/instant/create-opportunity" component={props => <CreateOpportunity {...props} />} />
                                <Route path="/instant/checkout" component={props => <Checkout {...props} />} />
                            </LeadCapture>
                            <Route component={PageNotFound} />
                        </Switch>
                    </Router>
                    <Loading />
                </ThemeProvider>
            </PersistGate>
        </Provider>
    </div>
};
