import View from "components/common/View";
import { store } from "components/initStore";
import React from "react";
import Aside from '../common/Aside';

const ErrorPage: React.FC= () => {
    // LY: We want to clear the application but keep the error, so we set it via state
    const [error] = React.useState(store.getState().error);
    store.dispatch({ type: "reset" });
    return (
        <View>
            <Aside explainer={<>
                <h1>Oops! We ran into an issue.</h1>
                <p>Please contact your sales representative or Gravity Support.</p>
            </>} />
            <div className="gp-form">
                <div className="gp-form-elements justify-center">
                    <div className="self-center f4 b">{error}</div>
                </div>
            </div>
        </View>
    );
};

export default ErrorPage;
