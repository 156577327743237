import React  from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Cleave from 'cleave.js/react';
import Aside from '../common/Aside';
import FileInfo from 'components/common/form/FileViewer';
import StepIndicator from '../common/StepIndicator';
import View, { ViewProps } from '../common/View';
import { store } from '../initStore';
import { BankingInfoFields, DocumentFile } from '../../interfaces';
import validators from '../../utils/validators';
import { BankNotFoundError, getBank } from '../../api';

type BankFormError = { documentError: string } & Partial<BankingInfoFields>;

interface IBankingFormState {
    fields: BankingInfoFields;
    errors: BankFormError;
    activeElement: string;
    bankName: string;
    bankNotFound: boolean,
}

const useBankState = (props: ViewProps) => {
    const initialValues: BankingInfoFields = { depositAccount: '', depositRouting: '', documents: [] };
    const initialErrors: BankFormError = { depositAccount: '', depositRouting: '', documentError: '' };

    const [bankState, setBankState] = React.useState<IBankingFormState>({
        fields: props.banking ? { ...props.banking } : { ...initialValues },
        errors: initialErrors,
        activeElement: null,
        bankName: props.bankName,
        bankNotFound: false,
    });

    function handleFieldChange(e: React.ChangeEvent<HTMLInputElement>) {
        e.persist();
        e.preventDefault();
        const name: string = e.target ? e.target.name : "";
        setBankState((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                [name]: e.target ? e.target.value : "",
            },
            errors: { ...prevState.errors, [name]: null },
            bankNotFound:
                name === "depositRouting" ? false : prevState.bankNotFound,
            bankName: name === "depositRouting" ? "" : prevState.bankName,
        }));
    }

    function handleSubmit(e: React.FormEvent) {
        e.preventDefault();
        if (formIsValid()) {
            store.dispatch({ type: 'banking', payload: { banking: bankState.fields, bankName: bankState.bankName } });
            props.history.push('/instant/additional-docs');
        } else {
            console.log({ text: `User failed validation for banking step`, fields: bankState.errors, bankNotFound: bankState.bankNotFound });
        }
    }

    function handleAddFile(data: DocumentFile) {
            setBankState((prevState) => ({
                ...prevState,
                fields: {
                    ...prevState.fields,
                    documents: [
                        ...prevState.fields.documents || [],
                        data,
                    ],
                },
                errors: { ...prevState.errors, documentError: null },
            }));
    }

    function elementActive(e) {
        const name: string = e.target ? e.target.name : '';
        setBankState(prevState => ({ ...prevState, activeElement: name }));
    }

    const documentValidator = () => {
        const { documents } = bankState.fields;
        const errors = {} as BankFormError;

        if (documents.length === 0) {
            errors.documentError = "Document is required."
        } else if(documents.every(doc => !doc.name.trim())) {
            errors.documentError = "Document Name is required."
        } else {
            errors.documentError = null;
        }

        return errors;

    }

    const validations = {
        depositRouting: () => validators.depositRouting(bankState.fields.depositRouting),
        depositAccount: () => validators.depositAccount(bankState.fields.depositAccount),
        document: documentValidator
    };

    // Banking Name
    React.useEffect(() => {
        const getBankName = async () => {
            try {
                store.dispatch({ type: 'loading', payload: { loading: true } });
                setBankState(prevState => ({ ...prevState, bankName: '', bankNotFound: false }));
                const bankName = await getBank(bankState.fields.depositRouting);
                setBankState(prevState => ({ ...prevState, bankName }));
                store.dispatch({ type: 'loading', payload: { loading: false } });
            } catch (error) {
                if (error instanceof BankNotFoundError) {
                    console.error(`Bank not found`, {
                        depositRouting: bankState.fields.depositRouting,
                    });
                    setBankState((prevState) => ({
                        ...prevState,
                        bankName: null,
                        bankNotFound: true,
                        errors: {
                            ...prevState.errors,
                            bankName:
                                "We couldn't find that bank. Please double check your entry or contact Gravity support.",
                        },
                    }));
                } else {
                    console.error(`Error fetching bank`, { error });
                }
            } finally {
                store.dispatch({ type: 'loading', payload: { loading: false }});
            }
        }

        if (bankState.fields.depositRouting && bankState.fields.depositRouting.length === 9 && !bankState.bankName) {
            getBankName();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bankState.fields.depositRouting]);

    function elementBlur(e) {
        if (e.currentTarget || e.target) {
            const name = e.currentTarget.name || e.target.name;
            const validation = validations[name];
            const value =  e.currentTarget.value || e.target.value || '';

            if (validation) {
                const newError = validation(value);
                setBankState(prevState => ({
                    ...prevState,
                    errors: { ...prevState.errors, ...newError },
                    activeElement: null,
                }));
            }
        }
    }

    function formIsValid() {
        let newErrors = {} as BankFormError;
        Object.values(validations).forEach(validate => {
            const error = validate();
            newErrors = {...newErrors, ...error}
        })
        setBankState(prevState => ({ ...prevState, errors: newErrors }));
        return Object.values(newErrors).every(field => field === '' || field === null);
    }

    function handleRemoveDocument(index: number) {
        const { documents } = bankState.fields
        const tempDoc = documents
        tempDoc.splice(index, 1)
        setBankState(prevState => ({ ...prevState, fields: { ...prevState.fields, documents: tempDoc }}));
    }

    const handleBack = () => {
        const { goBack } = props.history;
        store.dispatch({ type: 'banking', payload: { banking: bankState.fields, bankName: bankState.bankName } });
        goBack();
    }

    const handleDocNameChange = (value, i) => {
        const documents = bankState.fields.documents

        const file = documents[i];
        file.name = value;
        documents[i] = file;

        setBankState((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                documents: documents,
            },
            errors: { ...prevState.errors, documentError: null },
        }));
    }

    return {
        bankState,
        handleBack,
        handleFieldChange,
        handleDocNameChange,
        handleRemoveDocument,
        handleSubmit,
        handleAddFile,
        elementActive,
        elementBlur,
    };
}

const Banking = ({ loading = false, ...props }: ViewProps) => {
    const {
        bankState,
        handleFieldChange,
        handleSubmit,
        handleAddFile,
        handleDocNameChange,
        handleRemoveDocument,
        handleBack,
        elementActive,
        elementBlur,
    } = useBankState(props);

    const { depositAccount, depositRouting, documents = [] } = bankState.fields;

    return <View>
        <Aside
            explainer={<>
                <h1>TELL US YOUR BANK INFORMATION</h1>
                <p>Let us know the business checking account you want deposits sent to. This should be the same account as the voided check you’ll upload shortly.</p>
            </>}
            field={bankState.activeElement}
        />
        <form className="gp-form" id="#banking" noValidate onSubmit={handleSubmit}>
            <div className="gp-form-elements">
                <StepIndicator pageStep={props.isCNPAgreement ? 6 : 5} />
                <div className="form-group">
                    <div className="input-full">
                        <label>Deposit Routing Number</label>
                        <Cleave
                            name="depositRouting"
                            data-dd-privacy="mask-user-input"
                            value={depositRouting}
                            onBlur={elementBlur}
                            onFocus={elementActive}
                            options={{ numericOnly: true }}
                            onChange={handleFieldChange}
                            required
                            className='input-third'
                            minLength={9}
                            maxLength={9}
                            inputMode="numeric"
                        />
                        {bankState.errors.depositRouting && <div className="error-message">{bankState.errors.depositRouting}</div>}
                        {bankState.bankNotFound && <div className="error-message warning ">We couldn't find that bank. Please double check your entry before submitting.</div>}
                        {bankState.bankName && <div className='f7 db gray' style={{ marginTop: '-0.5rem' }}>{bankState.bankName}</div>}
                    </div>
                    <div className="input-full">
                        <label>Deposit Account</label>
                        <Cleave
                            name="depositAccount"
                            data-dd-privacy="mask-user-input"
                            value={depositAccount}
                            onBlur={elementBlur}
                            onFocus={elementActive}
                            options={{ numericOnly: true }}
                            onChange={handleFieldChange}
                            required
                            className='input-third'
                            minLength={3}
                            maxLength={17}
                            inputMode="numeric"
                        />
                        {bankState.errors.depositAccount && <div className="error-message">{bankState.errors.depositAccount}</div>}
                    </div>
                    <div className="input-full">
                        <label>Upload Voided Check or Bank Letter</label>
                        <span className='f7 db gray'>File must be less than 5MB. Allowed file types: .jpeg, .png and .pdf. </span>

                        {documents.length
                                ? documents.map((document, i) => (
                                      <FileInfo
                                          key={i + document.name}
                                          label={document.name}
                                          file={document}
                                          showPreview={false}
                                          handleInputBlur={(e) => handleDocNameChange(e, i)}
                                          handleRemove={() => handleRemoveDocument(i)}
                                      />
                                  ))
                                : null}

                        {documents.length < 1 ? (<>
                                <FileInfo
                                    label="Upload Voided Check or Bank Letter"
                                    handleUpload={handleAddFile}
                                    showPreview={false}
                                    isEditable={false}
                                />
                                </>
                            ) : null}

                        {bankState.errors.documentError && <div className="error-message">{bankState.errors.documentError}</div>}
                    </div>
                </div>
                <div className="flex">
                    <button disabled={loading} type="button" className="db back mt-auto" onClick={handleBack}>Back</button>
                    <button type="submit" disabled={loading} className="submit db ml-auto mt-auto self-end">Continue</button>
                </div>
            </div>
        </form>
    </View>;
}
const mapStateToProps = state => ({ banking: state.banking, bankName: state.bankName, loading: state.loading, isCNPAgreement: state.isCNPAgreement, opportunityId: state.opportunityId });
export default connect(mapStateToProps)(withRouter(Banking));
