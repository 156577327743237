import React from 'react';
import { USAStates } from '../../models';
import { Dropdown } from '../Dropdown';

type AddressFormFields = {
    addressLineOne: string;
    addressLineTwo: string;
    city: string;
    state: string;
    zipcode: string;
    [key: string]: string;
}

interface IAddressFormProps {
    fields: AddressFormFields;
    errors: AddressFormFields;
    handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
    className?: string;
    onBlur?: (e: React.FocusEvent<HTMLInputElement | HTMLSelectElement>) => void;
    title: string;
}

export const AddressForm = (props: IAddressFormProps) => {
    const {
        className = '',
        title,
    } = props;

    return <div className={`${className} flex flex-column input-full`}>
        <label className="db">{title}</label>
        <div className="flex flex-column flex-row-ns justify-between input-full">
            <div className="input-half">
                <label className="db dn-ns" htmlFor="addressLineOne">Address Line One</label>
                <input value={props.fields.addressLineOne} onBlur={props.onBlur ? props.onBlur : null} onChange={props.handleChange} maxLength={42} placeholder="Address Line One"
                       type="text" name="addressLineOne" required/>
                {props.errors.addressLineOne && <div className="error-message">{props.errors.addressLineOne}</div>}
            </div>
            <div className="input-half">
                <label className="db dn-ns" htmlFor="addressLineTwo">Address Line Two</label>
                <input value={props.fields.addressLineTwo} onBlur={props.onBlur} onChange={props.handleChange} maxLength={24} placeholder="Address Line Two"
                       type="text" name="addressLineTwo"/>
            </div>
        </div>
        <div className="flex flex-column flex-row-ns justify-between">
            <div className="input-third">
                <label className="db dn-ns" htmlFor="city">City</label>
                <input value={props.fields.city} onChange={props.handleChange} onBlur={props.onBlur} placeholder="City" name="city" type="text" maxLength={28} required/>
                {props.errors.city && <div className="error-message">{props.errors.city}</div>}
            </div>
            <Dropdown
                selected={props.fields.state}
                selectName="state"
                firstOption="Select a state"
                options={USAStates}
                onChange={props.handleChange}
                onBlur={props.onBlur}
                error={props.errors.state}
                required/>

            <div className="input-third">
                <label className="db dn-m dn-l" htmlFor="zipcode">Zip</label>
                <input value={props.fields.zipcode} onChange={props.handleChange} onBlur={props.onBlur} placeholder="Zip Code" name="zipcode" type="text" maxLength={5} minLength={5} required inputMode="numeric"/>
                {props.errors.zipcode && <div className="error-message">{props.errors.zipcode}</div>}
            </div>
        </div>
    </div>
}
