export const IndustryCategories = [
    { label: 'Beauty and Personal Care', value: 'beautyAndPersonalCare' },
    { label: 'Casual Use', value: 'casualUse' },
    { label: 'Charities, Education and Membership', value: 'charitiesEducationAndMembership' },
    { label: 'Food and Drink', value: 'foodAndDrink' },
    { label: 'Health Care and Fitness', value: 'healthCareAndFitness' },
    { label: 'Home and Repair', value: 'homeAndRepair' },
    { label: 'Leisure and Entertainment', value: 'leisureAndEntertainment' },
    { label: 'Professional Services', value: 'professionalServices' },
    { label: 'Retail', value: 'retail' },
]

// Sic Code is not currently used but want to include in future release:
// depends on changes to the Account API;
export const IndustryTypes = {
    beautyAndPersonalCare: [
        { sicCode: '7231', label: 'Beauty Salon', value: 'Beauty Salon' },
        { sicCode: '7241', label: 'Hair Salon / Barbershop', value: 'Hair Salon or Barber Shop' },
        { sicCode: '7241', label: 'Independent Stylist / Barber', value: 'Independent Stylist' },
        { sicCode: '7299', label: 'Massage Therapist', value: 'Massage Therapist' },
        { sicCode: '7231', label: 'Nail Salon', value: 'Nail Salon' },
        { sicCode: '7299', label: 'Spa', value: 'Spa' },
        { sicCode: '7299', label: 'Tanning Salon', value: 'Tanning Salon' },
        { sicCode: '7299', label: 'Tattoo / Piercing', value: 'Tattoo Piercing' },
        { sicCode: '7299', label: 'Other', value: 'Other Beauty and Barber Shops' },
    ],

    casualUse: [
        { sicCode: '7389', label: 'Events / Festivals', value: 'Events Festivals' },
        { sicCode: '5999', label: 'Miscellaneous Goods', value: 'Miscellaneous and Specialty Retail' },
        { sicCode: '7299', label: 'Miscellaneous Services', value: 'Miscellaneous Personal Services' },
        { sicCode: '5999', label: 'Outdoor Markets', value: 'Miscellaneous and Specialty Retail' },
        { sicCode: '5999', label: 'Other', value: 'Other Miscellaneous and Specialty Retail' },
    ],

    charitiesEducationAndMembership: [
        { sicCode: '8399', label: 'Charitable Organization', value: 'Charitable Organization' },
        { sicCode: '8351', label: 'Child Care', value: 'Child Care' },
        { sicCode: '8299', label: 'Instructor / Teacher', value: 'Instructor or Teacher' },
        { sicCode: '7997', label: 'Recreation / Athletic Membership Organization', value: 'Membership Organization Recreation' },
        { sicCode: '8699', label: 'Membership Organization', value: 'Membership Organization' },
        { sicCode: '8651', label: 'Political Organization', value: 'Political Organization' },
        { sicCode: '8661', label: 'Religious Organization', value: 'Religious Organization' },
        { sicCode: '8299', label: 'School', value: 'School' },
        { sicCode: '8299', label: 'Tutor', value: 'Tutor' },
        { sicCode: '8299', label: 'Other', value: 'Other Education Services' },
    ],

    foodAndDrink: [
        { sicCode: '5812', label: 'Table Service Restaurant', value: 'Table Service Restaurant' },
        { sicCode: '5814', label: 'Quick Service Restaurant', value: 'Quick Service Restaurant' },
        { sicCode: '5813', label: 'Bar / Club / Lounge', value: 'Bar or Club or Lounge' },
        { sicCode: '5812', label: 'Bakery', value: 'Bakery' },
        { sicCode: '5812', label: 'Caterer', value: 'Caterer' },
        { sicCode: '5814', label: 'Coffee Shop / Cafe', value: 'Coffee Shop or Cafe' },
        { sicCode: '5411', label: 'Convenience Store', value: 'Convenience Store' },
        { sicCode: '5812', label: 'Food Truck / Cart', value: 'Food Truck or Cart' },
        { sicCode: '5411', label: 'Grocery / Market', value: 'Grocery Market' },
    ],

    healthCareAndFitness: [
        { sicCode: '8099', label: 'Acupuncture', value: 'Acupuncture' },
        { sicCode: '8099', label: 'Alternative Medicine', value: 'Alternative Medicine' },
        { sicCode: '8082', label: 'Caregiver', value: 'Caregiver' },
        { sicCode: '8041', label: 'Chiropractor', value: 'Chiropractor' },
        { sicCode: '8021', label: 'Dentist / Orthodontist', value: 'Dentist Orthodontist' },
        { sicCode: '7997', label: 'Gym / Health Club', value: 'Gym Health Club' },
        { sicCode: '7299', label: 'Massage Therapist', value: 'Massage Therapist' },
        { sicCode: '8011', label: 'Medical Practitioner', value: 'Medical Practitioner' },
        { sicCode: '8042', label: 'Optometrist / Eye Care', value: 'Optometrist or Eye Care' },
        { sicCode: '7299', label: 'Personal Trainer', value: 'Personal Trainer' },
        { sicCode: '6211', label: 'Psychiatrist', value: 'Psychiatrist' },
        { sicCode: '8049', label: 'Therapist', value: 'Therapist' },
        { sicCode: '0742', label: 'Veterinary Services', value: 'Veterinary Services' },
        { sicCode: '8099', label: 'Other', value: 'Other Health Services' },
    ],

    homeAndRepair: [
        { sicCode: '7538', label: 'Automotive Services', value: 'Automotive Service Shops' },
        { sicCode: '7217', label: 'Carpet Cleaning', value: 'Carpet Cleaning' },
        { sicCode: '7349', label: 'Cleaning', value: 'Cleaning' },
        { sicCode: '7251', label: 'Shoe Repair', value: 'Shoe Repair' },
        { sicCode: '5697', label: 'Tailor / Seamstress / Mending / Alterations', value: 'Tailor Seamstress Mending Alterations' },
        { sicCode: '7397', label: 'Computer / Electronics / Appliances', value: 'Computer and Electronic Repair' },
        { sicCode: '7216', label: 'Dry Cleaning and Laundry', value: 'Dry Cleaners' },
        { sicCode: '7629', label: 'Electrical Services', value: 'Electrical Services' },
        { sicCode: '5713', label: 'Flooring', value: 'Flooring' },
        { sicCode: '1520', label: 'General Contracting', value: 'General Contracting' },
        { sicCode: '1711', label: 'Heating and Air Conditioning', value: 'HVAC' },
        { sicCode: '1799', label: 'Installation Services', value: 'Installation Services' },
        { sicCode: '7399', label: 'Junk Removal', value: 'Junk Removal' },
        { sicCode: '0780', label: 'Landscaping', value: 'Landscaping' },
        { sicCode: '7399', label: 'Locksmith Services', value: 'Locksmith Services' },
        { sicCode: '4214', label: 'Moving', value: 'Moving' },
        { sicCode: '1799', label: 'Painting', value: 'Painting' },
        { sicCode: '7342', label: 'Pest Control', value: 'Pest Control' },
        { sicCode: '1711', label: 'Plumbing', value: 'Plumbing' },
        { sicCode: '1761', label: 'Roofing', value: 'Roofing' },
        { sicCode: '7631', label: 'Watch / Jewelry Repair', value: 'Watch or Jewelry Repair' },
        { sicCode: '7399', label: 'Other', value: 'Other Business Services' },
    ],

    leisureAndEntertainment: [
        { sicCode: '7991', label: 'Events / Festivals', value: 'Events or Festivals' },
        { sicCode: '7832', label: 'Movies / Film', value: 'Movies or Film' },
        { sicCode: '7991', label: 'Museum / Cultural', value: 'Museum or Cultural' },
        { sicCode: '5733', label: 'Musical Instruments', value: 'Musical Instruments' },
        { sicCode: '5735', label: 'Record / CD Store', value: 'Music Store' },
        { sicCode: '7999', label: 'Performing Arts', value: 'Performing Arts' },
        { sicCode: '5499', label: 'Sporting Events', value: 'Sporting Events' },
        { sicCode: '7999', label: 'Sports Recreation', value: 'Sports Recreation' },
        { sicCode: '7991', label: 'Tourism', value: 'Tourism' },
        { sicCode: '5499', label: 'Other', value: 'Other Entertainment' },
    ],

    professionalServices: [
        { sicCode: '8931', label: 'Accounting', value: 'Accounting' },
        { sicCode: '8351', label: 'Child Care', value: 'Child Care' },
        { sicCode: '7392', label: 'Consulting', value: 'Consulting' },
        { sicCode: '4215', label: 'Delivery', value: 'Delivery' },
        { sicCode: '7311', label: 'Design', value: 'Design' },
        { sicCode: '7399', label: 'Interior Design', value: 'Interior Design' },
        { sicCode: '8111', label: 'Legal Services', value: 'Legal Services' },
        { sicCode: '7311', label: 'Marketing / Advertising', value: 'Marketing or Advertising' },
        { sicCode: '8351', label: 'Nanny Services', value: 'Nanny Services' },
        { sicCode: '8111', label: 'Notary Services', value: 'Notary Services' },
        { sicCode: '7221', label: 'Photography', value: 'Photography' },
        { sicCode: '5741', label: 'Printing Services', value: 'Printing Services' },
        { sicCode: '6531', label: 'Real Estate', value: 'Real Estate' },
        { sicCode: '7372', label: 'Software Development', value: 'Software Development' },
        { sicCode: '8999', label: 'Other', value: 'Other Professional Services' },
    ],

    retail: [
        { sicCode: '5999', label: 'Art, Photo and Film', value: 'Art Galleries and Dealers' },
        { sicCode: '5942', label: 'Books / Magazines', value: 'Book Store' },
        { sicCode: '5735', label: 'Music and Video', value: 'Music Video Store' },
        { sicCode: '5651', label: 'Clothing and Accessories', value: 'Clothing and Accessories' },
        { sicCode: '5411', label: 'Convenience Store', value: 'Convenience Store' },
        { sicCode: '5732', label: 'Electronics', value: 'Electronics' },
        { sicCode: '8043', label: 'Eyewear', value: 'Eyewear' },
        { sicCode: '5193', label: 'Flowers and Gifts', value: 'Flowers and Gifts' },
        { sicCode: '5712', label: 'Furniture / Home Goods', value: 'Furniture or Home Goods' },
        { sicCode: '5411', label: 'Grocery / Market', value: 'Grocery or Market' },
        { sicCode: '5251', label: 'Hardware Store', value: 'Hardware Store' },
        { sicCode: '5945', label: 'Hobby Shop', value: 'Hobby Shop' },
        { sicCode: '5944', label: 'Jewelry and Watches', value: 'Jewelry and Watches' },
        { sicCode: '5943', label: 'Office Supply', value: 'Office Supply' },
        { sicCode: '5999', label: 'Outdoor Markets', value: 'Miscellaneous and Specialty Retail' },
        { sicCode: '5995', label: 'Pet Store', value: 'Pet Store' },
        { sicCode: '5999', label: 'Specialty Store', value: 'Specialty Store' },
        { sicCode: '5941', label: 'Sporting Goods', value: 'Sporting Goods Stores' },
        { sicCode: '5999', label: 'Other', value: 'Other Miscellaneous-and-Specialty Retail' },
    ],
}

export const LegalEntityTypes = [
    {
        label: 'Public Corp',
        value: 'Public Corp',
    },
    {
        label: 'Private Corp',
        value: 'Private Corp',
    },
    {
        label: 'Publicly Traded Partnership',
        value: 'Publicly Traded Partnership',
    },
    {
        label: 'Private Partnership',
        value: 'Private Partnership',
    },
    {
        label: 'Publicly Traded LLC',
        value: 'Publicly Traded LLC',
    },
    {
        label: 'Private LLC',
        value: 'Private LLC',
    },
    {
        label: 'Sole Proprietorship',
        value: 'Sole Proprietorship',
    },
    {
        label: 'Association/Estate/Trust',
        value: 'Association/Estate/Trust',
    },
    {
        label: 'Tax-Exempt Organization',
        value: 'Tax-Exempt Organization',
    },
]

export const OwnerTypes = [
    { value: 'President', label: 'President' },
    { value: 'Vice President', label: 'Vice President' },
    { value: 'Treasurer', label: 'Treasurer' },
    { value: 'Owner', label: 'Owner' },
    { value: 'Partner', label: 'Partner' },
    { value: 'CEO', label: 'CEO' },
    { value: 'Secretary', label: 'Secretary' },
    { value: 'Director', label: 'Director' },
]

export const BillingDuration = [
    { value: 'Monthly', label: 'Monthly' },
    { value: 'Quarterly', label: 'Quarterly' },
    { value: 'Bi-annual', label: 'Bi-annual' },
    { value: 'Annual', label: 'Annual' },
]

export const USAStates = [
    { value: 'AL', label: 'Alabama' },
    { value: 'AK', label: 'Alaska' },
    { value: 'AZ', label: 'Arizona' },
    { value: 'AR', label: 'Arkansas' },
    { value: 'CA', label: 'California' },
    { value: 'CO', label: 'Colorado' },
    { value: 'CT', label: 'Connecticut' },
    { value: 'DE', label: 'Delaware' },
    { value: 'DC', label: 'District Of Columbia' },
    { value: 'FL', label: 'Florida' },
    { value: 'GA', label: 'Georgia' },
    { value: 'HI', label: 'Hawaii' },
    { value: 'ID', label: 'Idaho' },
    { value: 'IL', label: 'Illinois' },
    { value: 'IN', label: 'Indiana' },
    { value: 'IA', label: 'Iowa' },
    { value: 'KS', label: 'Kansas' },
    { value: 'KY', label: 'Kentucky' },
    { value: 'LA', label: 'Louisiana' },
    { value: 'ME', label: 'Maine' },
    { value: 'MD', label: 'Maryland' },
    { value: 'MA', label: 'Massachusetts' },
    { value: 'MI', label: 'Michigan' },
    { value: 'MN', label: 'Minnesota' },
    { value: 'MS', label: 'Mississippi' },
    { value: 'MO', label: 'Missouri' },
    { value: 'MT', label: 'Montana' },
    { value: 'NE', label: 'Nebraska' },
    { value: 'NV', label: 'Nevada' },
    { value: 'NH', label: 'New Hampshire' },
    { value: 'NJ', label: 'New Jersey' },
    { value: 'NM', label: 'New Mexico' },
    { value: 'NY', label: 'New York' },
    { value: 'NC', label: 'North Carolina' },
    { value: 'ND', label: 'North Dakota' },
    { value: 'OH', label: 'Ohio' },
    { value: 'OK', label: 'Oklahoma' },
    { value: 'OR', label: 'Oregon' },
    { value: 'PA', label: 'Pennsylvania' },
    { value: 'RI', label: 'Rhode Island' },
    { value: 'SC', label: 'South Carolina' },
    { value: 'SD', label: 'South Dakota' },
    { value: 'TN', label: 'Tennessee' },
    { value: 'TX', label: 'Texas' },
    { value: 'UT', label: 'Utah' },
    { value: 'VT', label: 'Vermont' },
    { value: 'VA', label: 'Virginia' },
    { value: 'WA', label: 'Washington' },
    { value: 'WV', label: 'West Virginia' },
    { value: 'WI', label: 'Wisconsin' },
    { value: 'WY', label: 'Wyoming' },
];
