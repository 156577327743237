import React from "react";
import Aside from "components/common/Aside";
import View, { ViewProps } from "../common/View";
import { CNPFields, DocumentFile } from "interfaces";
import { store } from "../initStore";
import validators from "utils/validators";
import StepIndicator from "components/common/StepIndicator";
import { IsOrRadioForm } from "components/common/form/IsOrRadioForm";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Dropdown } from "components/common/Dropdown";
import { BillingDuration } from "components/models";
import FileInfo from '../common/form/FileViewer';

interface ICNPState {
    fields: CNPFields;
    errors: CNPFormError;
    activeElement: string;
}

type CNPFormError = Partial<CNPFields> & { contractError: string, samplesError: string };

const useCNPAgreement = (props: ViewProps) => {
    const initialValues: CNPFields = {
        productAdvertisedOn: "",
        vendorNamesAndAddress: "",
        productServiceProvided: "",
        howCustomerOrderProduct: "",
        creditAuthDuration: "",
        txnCompletionBeforeShipDuration: "",
        doesCustomerSignContract: "no",
        doesRecurringBilling: "no",
        recurringBillingDuration: "",
        storesCardNumbers: "no",
        pciCompliant: "no",
        contract: [],
        samples: [],
    };
    const initialErrors: CNPFormError = {
        productAdvertisedOn: "",
        vendorNamesAndAddress: "",
        productServiceProvided: "",
        howCustomerOrderProduct: "",
        creditAuthDuration: "",
        txnCompletionBeforeShipDuration: "",
        doesCustomerSignContract: "no",
        doesRecurringBilling: "no",
        recurringBillingDuration: "",
        storesCardNumbers: "no",
        pciCompliant: "yes",
        contractError: '',
        samplesError: '',
    };
    const [cnpState, setCNPState] = React.useState<ICNPState>({
        fields: props.cnpAgreement ? { ...props.cnpAgreement } : { ...initialValues },
        errors: initialErrors,
        activeElement: null,
    });

    const validations = {
        productAdvertisedOn: () =>
            validators.required(
                cnpState.fields.productAdvertisedOn,
                "productAdvertisedOn",
                "This"
            ),
        vendorNamesAndAddress: () =>
            validators.required(
                cnpState.fields.vendorNamesAndAddress,
                "vendorNamesAndAddress",
                "This"
            ),
        productServiceProvided: () =>
            validators.required(
                cnpState.fields.productServiceProvided,
                "productServiceProvided",
                "This"
            ),
        howCustomerOrderProduct: () =>
            validators.required(
                cnpState.fields.howCustomerOrderProduct,
                "howCustomerOrderProduct",
                "This"
            ),
        creditAuthDuration: () =>
            validators.required(
                cnpState.fields.creditAuthDuration,
                "creditAuthDuration",
                "This"
            ),
        txnCompletionBeforeShipDuration: () =>
            validators.required(
                cnpState.fields.txnCompletionBeforeShipDuration,
                "txnCompletionBeforeShipDuration",
                "This"
            ),
        recurringBillingDuration: () =>
            cnpState.fields.doesRecurringBilling === "yes"
                ? validators.required(
                    cnpState.fields.recurringBillingDuration,
                    "recurringBillingDuration",
                    "This"
                )
                : {},
    };

    function elementBlur(e) {
        if (e.currentTarget || e.target) {
            const name = e.currentTarget.name || e.target.name;
            const validation = validations[name];
            if (validation) {
                const newError = validation();
                setCNPState((prevState) => ({
                    ...prevState,
                    errors: { ...prevState.errors, ...newError },
                }));
            }
        }
    }

    function handleChange(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) {
        if (e.currentTarget || e.target) {
            const name = e.currentTarget.name || e.target.name;
            let value = e.currentTarget.value || e.target.value;
            setCNPState((prevState) => ({
                ...prevState,
                fields: { ...prevState.fields, [name]: value },
                errors: { ...prevState.errors, [name]: null },
            }));
        }
    }

    function handleSubmit(e: React.FormEvent) {
        e.preventDefault();
        if (formIsValid()) {
            store.dispatch({
                type: "cnpAgreement",
                payload: { ...cnpState.fields },
            });
            if (props.opportunityId) {
                props.history.push("/instant/banking");
            } else {
                props.history.push("/instant/processing");
            }
        } else {
            console.log({
                text: `User failed validation for CNP agreement step`,
                fields: cnpState.errors,
            });
        }
    }

    function formIsValid() {
        let newErrors = {} as CNPFormError;

        Object.values(validations).forEach((validate) => {
            const error = validate();
            newErrors = { ...newErrors, ...error };
        });
        setCNPState((prevState) => ({ ...prevState, errors: newErrors }));
        return Object.values(newErrors).every(field => field === "" || field === null) && !props.loading;
    }

    function elementActive(e) {
        const name: string = e.target ? e.target.name : "";
        setCNPState((prevState) => ({
            ...prevState,
            activeElement: name,
        }));
    }

    const handleBack = () => {
        const { goBack } = props.history;
        store.dispatch({
            type: "cnpAgreement",
            payload: { ...cnpState.fields },
        });
        goBack();
    };

    return {
        cnpState,
        handleChange,
        elementBlur,
        handleSubmit,
        handleBack,
        elementActive,
        setCNPState,
    };
};

const CNPAgreement = (props: ViewProps) => {
    const {
        cnpState,
        handleChange,
        elementBlur,
        handleSubmit,
        handleBack,
        setCNPState,
    } = useCNPAgreement(props);
    const { loading = false } = props;
    const {
        pciCompliant,
        contract,
        creditAuthDuration,
        doesCustomerSignContract,
        storesCardNumbers,
        doesRecurringBilling,
        howCustomerOrderProduct,
        productAdvertisedOn,
        productServiceProvided,
        recurringBillingDuration,
        samples,
        txnCompletionBeforeShipDuration,
        vendorNamesAndAddress,
    } = cnpState.fields;

    const [placeHolders, setPlaceHolders] = React.useState<{ name: string }[]>([{ name: 'Product brochure, promotional materials, product catalogue, etc.' }]);

    const handleAddSample = (file: DocumentFile) => {
        placeHolders.pop();
        setPlaceHolders([...placeHolders]);
        setCNPState(prev => ({
            ...prev,
            fields: { ...prev.fields, samples: [...prev.fields.samples, file] },
            errors: { ...prev.errors, samplesError: null },
        }));
    }

    const handleRemoveSample = (index: number) => {
        const temp = [...samples];
        temp.splice(index, 1);
        setCNPState(prevState => ({ ...prevState, fields: { ...prevState.fields, samples: temp }}));
        if (!temp.length && !placeHolders.length) {
            setPlaceHolders([{ name: 'Product brochure, promotional materials, product catalogue, etc.' }]);
        }
    }

    const handleAddContract = (file: DocumentFile) => {
        setCNPState(prev => ({
            ...prev,
            fields: { ...prev.fields, contract: [...prev.fields.contract || [], file] },
            errors: { ...prev.errors, contractError: null },
        }));
    }

    const handleRemoveContract = (index: number) => {
        const temp = [...contract];
        temp.splice(index, 1);
        setCNPState(prevState => ({ ...prevState, fields: { ...prevState.fields, contract: temp}}));

    }

    const handleDocumentNameChange = (type: 'samples'|'contract') => (value, i) => {
        const file = cnpState.fields[type][i];
        file.name = value;
        setCNPState(prevState => ({
            ...prevState,
            fields: { ...prevState.fields, [type]: [...cnpState.fields[type]]},
            errors: { ...prevState.errors, [`${type}Error`]: null },
        }));
    }

    return <View>
        <Aside
            explainer={<><h1>Card Not Present Processing Agreement</h1></>}
            field={cnpState.activeElement}
        />
        <form
            className="gp-form"
            id="#CNP-agreement"
            noValidate
            onSubmit={handleSubmit}
        >
            <div className="gp-form-elements">
                <StepIndicator pageStep={5}/>
                <div className="form-group">
                    <div className="input-full">
                        <label>
                            How will product be advertised or promoted? (if
                            advertising on the internet, provide website
                            address)
                        </label>
                        <textarea
                            className="input-full"
                            value={productAdvertisedOn}
                            onChange={handleChange}
                            onBlur={elementBlur}
                            placeholder="How will product be advertised or promoted?"
                            name="productAdvertisedOn"
                            rows={2}
                            required
                        />
                        {cnpState.errors.productAdvertisedOn && <div className="error-message">{cnpState.errors.productAdvertisedOn}</div>}
                    </div>
                    <div className="input-full">
                        <label>Submit sample(s) of product brochure, promotional materials, product catalogue, etc.</label>
                        <span className="f7 db gray">File must be less than 5MB and Max. 5 files. Allowed file types: .jpeg, .png and .pdf.</span>
                        {samples.length ? samples.map((document, i) => <FileInfo
                            key={i + document.name}
                            label={document.name}
                            file={document}
                            showPreview={false}
                            handleRemove={() => handleRemoveSample(i)}
                            handleInputBlur={(val) => handleDocumentNameChange('samples')(val, i)}
                        />): null}
                        {placeHolders.length ? placeHolders.map((_, i) => <FileInfo
                            key={`${i}-upload`}
                            label="Product brochure, promotional materials, product catalogue, etc."
                            handleUpload={handleAddSample}
                            showPreview={false}
                            isEditable={false}
                            onLoading={loading => store.dispatch({ type: 'loading', payload: { loading }})}
                        />): null}
                        {cnpState.errors.samplesError && <div className="error-message">{cnpState.errors.samplesError}</div>}
                        <div className="flex mb3 mt3">
                            <button
                                className="db add text-black f7"
                                onClick={() => setPlaceHolders(prev => [...prev, { name: 'Product brochure, promotional materials, product catalogue, etc.' }])}
                                type="button"
                                disabled={(placeHolders.length + samples.length) >= 5}
                            >Add extra files
                            </button>
                        </div>
                    </div>
                    <div className="input-full">
                        <label>List name(s) and address(es) of vendor from which product is purchased:</label>
                        <textarea
                            className="input-full"
                            value={vendorNamesAndAddress}
                            onChange={handleChange}
                            onBlur={elementBlur}
                            placeholder="Vendor Name(s) and Address(es)"
                            name="vendorNamesAndAddress"
                            rows={2}
                            required
                        />
                        {cnpState.errors.vendorNamesAndAddress && <div className="error-message">{cnpState.errors.vendorNamesAndAddress}</div>}
                    </div>
                    <div className="input-full">
                        <label>
                            What product(s) or service(s) does your
                            organization provide the cardholder? (please be
                            specific)”
                        </label>
                        <textarea
                            className="input-full"
                            value={productServiceProvided}
                            onChange={handleChange}
                            onBlur={elementBlur}
                            placeholder="Product(s) or Service(s) does your organization provide the cardholder"
                            name="productServiceProvided"
                            rows={2}
                            required
                        />
                        {cnpState.errors.productServiceProvided && <div className="error-message">{cnpState.errors.productServiceProvided}</div>}
                    </div>
                    <div className="input-full">
                        <label>
                            How does the customer order the product/service?
                            (please provide the process from customer order
                            to shipment)
                        </label>
                        <textarea
                            className="input-full"
                            value={howCustomerOrderProduct}
                            onChange={handleChange}
                            onBlur={elementBlur}
                            placeholder="How does the customer order the product/service?"
                            name="howCustomerOrderProduct"
                            rows={2}
                            required
                        />
                        {cnpState.errors.howCustomerOrderProduct && <div className="error-message">{cnpState.errors.howCustomerOrderProduct}</div>}
                    </div>
                    <div className="input-half">
                        <label>
                            How long after credit card authorization is the
                            transaction completed?
                        </label>
                        <input
                            className="input-full"
                            value={creditAuthDuration}
                            type="text"
                            onChange={handleChange}
                            onBlur={elementBlur}
                            placeholder="Credit card Authorization duration"
                            name="creditAuthDuration"
                            required
                        />
                        {cnpState.errors.creditAuthDuration && <div className="error-message">{cnpState.errors.creditAuthDuration}</div>}
                    </div>
                    <div className="input-half">
                        <label>
                            How long after the transaction is completed do
                            you ship the goods or provide the service?
                        </label>
                        <input
                            className="input-full"
                            type="text"
                            value={txnCompletionBeforeShipDuration}
                            onChange={handleChange}
                            onBlur={elementBlur}
                            placeholder="Duration to ship order after transaction"
                            name="txnCompletionBeforeShipDuration"
                            required
                        />
                        {cnpState.errors.txnCompletionBeforeShipDuration && <div className="error-message">{cnpState.errors.txnCompletionBeforeShipDuration}</div>}
                    </div>
                    <div className="input-full">
                        <div className="input-full mt3">
                            <IsOrRadioForm
                                label="Does the customer sign a contract specifying terms and conditions?"
                                name="doesCustomerSignContract"
                                selected={doesCustomerSignContract}
                                handleChange={handleChange}
                            />
                        </div>
                    </div>
                    {doesCustomerSignContract === 'yes' && <div className="input-full">
                        <label>Please provide a copy of said contract.</label>
                        <span className='f7 db gray'>File must be less than 5MB. Allowed file types: .jpeg, .png and .pdf. </span>
                        {contract.length ? contract.map((document, i) => <FileInfo
                            key={i + document.name}
                            label={document.name}
                            file={document}
                            showPreview={false}
                            handleInputBlur={(e) => handleDocumentNameChange('contract')(e, i)}
                            handleRemove={() => handleRemoveContract(i)}
                        />) : null}
                        {contract.length < 1 ? <FileInfo
                            label="Terms and Conditions"
                            handleUpload={handleAddContract}
                            showPreview={false}
                            isEditable={false}
                            onLoading={loading => store.dispatch({ type: 'loading', payload: { loading }})}
                        /> : null}
                        {cnpState.errors.contractError && <div className="error-message">{cnpState.errors.contractError}</div>}
                    </div>}
                    <div className="input-full">
                        <div className="input-full mt3">
                            <IsOrRadioForm
                                label="Do you perform recurring monthly billing?"
                                name="doesRecurringBilling"
                                selected={doesRecurringBilling}
                                handleChange={handleChange}
                            />
                        </div>
                    </div>
                    {doesRecurringBilling === "yes" && <div className="input-third">
                        <Dropdown
                            selectLabel="How often does billing recurring?"
                            selectName="recurringBillingDuration"
                            error={cnpState.errors.recurringBillingDuration}
                            firstOption="Billing duration"
                            options={BillingDuration}
                            selected={recurringBillingDuration}
                            className="input-full"
                            onChange={handleChange}
                            onBlur={elementBlur}
                            required
                        />
                    </div>}
                    <div className="input-full">
                        <div className="input-full mt3">
                            <IsOrRadioForm
                                label="Does your database collect entire credit card numbers?"
                                name="storesCardNumbers"
                                selected={storesCardNumbers}
                                handleChange={handleChange}
                            />
                        </div>
                    </div>
                    {storesCardNumbers === 'yes' && <div className="input-full">
                        <div className="input-full mt3">
                            <IsOrRadioForm
                                label="Are you PCI Compliant?"
                                name="pciCompliant"
                                selected={pciCompliant}
                                handleChange={handleChange}
                            />
                        </div>
                    </div>}
                </div>
                <div className="flex">
                    <button
                        type="button"
                        disabled={loading}
                        className="db back mt-auto"
                        onClick={handleBack}
                    >
                        Back
                    </button>
                    <button
                        type="submit"
                        disabled={loading}
                        className="submit db ml-auto mt-auto self-end"
                    >
                        Continue
                    </button>
                </div>
            </div>
        </form>
    </View>
};

const mapStateToProps = (state) => ({ cnpAgreement: state.cnpAgreement, opportunityId: state.opportunityId, loading: state.loading });
export default connect(mapStateToProps)(withRouter(CNPAgreement));
